fieldset {
	background-color: #f0f4fa;
	padding: 10px;
	border-radius: 3px;
	box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;

	legend {
		background-color: #f0f4fa;
		width: auto;
		padding: 5px 10px;
		border-radius: 3px;
		box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
	}

	.btn-collapse {
		position: relative;
		left: calc(100% - 33px);
		top: -25px;
	}
}