.skill-card {
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 10px;
	width: 200px;
	height: 230px;
	display: flex;
	flex-direction: column;
	place-content: space-between;

	.skill-card-header {
		padding: 10px 10px 0 10px;
		display: flex;
		place-content: space-between;

		.title {
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			color: #212529;
		}

		span {
			border-radius: 6px;
			font-size: 20px;
			padding: 5px;
			color: #FFF;
		}
	}

	.skill-card-body {
		margin: 0 10px;
		padding: 5px 10px;
		background-color: white;
		border-radius: 6px;

		.value {
			color: #212529;
		}
	}

	.skill-card-footer {
		padding: 0 10px 10px 10px;

		.btn {
			line-height: 17px;
			color: #FFF;
		}
	}

	&.skill-card-success {
		background-color: #cce9d2;

		.skill-card-header {
			span {
				background-color: #7cd68f;
			}
		}

		.skill-card-body {
			.value {
				color: #20bc40;
			}
		}
	}

	&.skill-card-warning {
		background-color: #fff0c4;

		.skill-card-header {
			span {
				background-color: #f2d067;
			}
		}

		.skill-card-body {
			.value {
				color: #e9b300;
			}
		}
	}

	&.skill-card-attention {
		background-color: #f9c295;

		.skill-card-header {
			span {
				background-color: #f19951;
			}
		}

		.skill-card-body {
			.value {
				color: #ee6c00;
			}
		}
	}

	&.skill-card-danger {
		background-color: #f6ced2;

		.skill-card-header {
			span {
				background-color: #e97f8b;
			}
		}

		.skill-card-body {
			.value {
				color: #e54153;
			}
		}
	}

	&.skill-card-info {
		background-color: #cee9f6;

		.skill-card-header {
			span {
				background-color: #7fd4e9;
			}
		}
	}

	&.skill-card-light {
		.skill-card-body {
			.value {
				color: gray;
			}
		}
	}
}
