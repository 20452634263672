.jumbotron {
	background-color: #f8f9fa;
}

.btn-plus {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	position: fixed;
	right: 2vh;
	bottom: 2vh;
	z-index: 9;
}

.btn-table { box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); }

@media (min-width: 1200px) {
	.animated-checkbox.checkbox-xl-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}
}

@media (max-width: 576px) {
	.animated-checkbox.checkbox-xs-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}

	.img-size-sm { height: 20vh; }
}

form { margin-block-end: 0; }

.filter-footer {
	width: 100%;
	bottom: 0;
	position: absolute;
}

.checked {
	position: absolute;
	right: 0;
	top:-3px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, .3);
}

.image-card {
	box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
}

.verified {
	letter-spacing: -5px;
	font-size: 1.3em;
}

.checked-one {
	right: 0;
}

.checked-two {
	right: 40px;
}

.checked-three {
	right: 80px;
}

.unchecked {
	color: #FF0000 !important;
}

.box-bt-image {
	position: absolute;
	top: 0px;
}

.mn-text-truncate {
	-webkit-line-clamp: 3;
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.card-disabled {
	opacity: 0.2;
}

.bt-select-image {
	right: 2px;
	bottom: 2px;
	color: #FFFFFF !important;
}

gap {
	margin: 2px;
	color: #FFF;
	background-color: #3085D6;
	border-color: #3085D6;
	cursor:pointer;
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	user-select: none;
}

.animated-checkbox.checkbox-lg input[type="checkbox"] + .label-text:before {
	font-size: 40px;
}

.mq-text-mode {
	font-family: 'tuffy2' !important;
}

.form-group.required label:after {
	content:"*";
	color:red;
	font-weight: bold;
}

.datetimepicker { z-index: 99999999; }

.bootstrap-select>.dropdown-toggle { border: 1px solid #CED4DA; }
.bootstrap-select button { border: 1px solid #CED4DA; }
.bootstrap-select .dropdown-menu { width: inherit; }

.mark {
	background-color: #C0FFC8 !important;
}

.icon-dashboard {
    display: flex;
    min-width: 85px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px 0 0 0;
    font-size: 2.5rem;
}

.icon-dashboard .material-icons {
    font-size: 36px;
}

.badge-questions {
	padding: 0;
	display: flex;
	width: 27px;
	height: 27px;
	justify-content: center;
	align-items: center;
	float: left;
	margin: 0 2px;
	box-shadow: inset 0 2px 2px rgba(0,0,0,0.3);
	user-select: none;

	&.badge-questions-sm {
		width: 20px;
		height: 20px;
	}

	&.badge-success { background-color: #81ad8b;  }
	&.badge-warning { background-color: #e5d194;  }
	&.badge-danger { background-color: #d3888f;  }
}

.permission-body .bootstrap-tagsinput {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.icon-side {
	width: 61px;
    min-height: 61px;
	height: 100%;
    margin: -20px 0px -35px -20px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

	@media (max-width: 949px) {
		width: 82px;
		min-height: 82px;
	}

	&.with-buttons {
		width: 75px;
		min-height: 75px;
	}

	span {
		font-size: 40px;
		color: #7A7A7A;
	}
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
	border-radius: 20rem;
}
.toggle.ios .toggle-handle {
	border-radius: 20rem;
}

.rotate-icon:not(.collapsed) {
	transform: rotate(180deg);
}

.cursor-pointer {
	cursor: pointer;
}

.btn-outline-secondary {
	background-color: white;
}

.question-editable div {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.alternative-editable div {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.distractor-editable div {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.tip-editable div {
    width: 100%;
    height: 100%;
    pointer-events: none;
}
