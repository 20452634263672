.swal2-popup {
	.swal2-actions {
		width: -webkit-fill-available;
		margin: 20px inherit !important;

		.swal2-styled {
			border-radius: 50px;
			padding: 0 40px;
			width: 152px;
			margin: 5px;
			margin: 0 2% !important;
			padding: 8px 30px !important;
		}

		// .swal2-confirm {

		// }
	}
}