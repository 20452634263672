::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}