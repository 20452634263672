/*\
|*| ========================================================================
|*| Bootstrap Toggle: bootstrap4-toggle.css v3.6.1
|*| https://gitbrent.github.io/bootstrap4-toggle/
|*| ========================================================================
|*| Copyright 2018-2019 Brent Ely
|*| Licensed under MIT
|*| ========================================================================
\*/
.btn-group-xs > .btn, .btn-xs {
  padding: 0.35rem 0.4rem 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.checkbox label .toggle, .checkbox-inline .toggle {
  margin-left: -1.25rem;
  margin-right: 0.35rem;
}

.toggle {
  position: relative;
  overflow: hidden;
}

.toggle.btn.btn-light, .toggle.btn.btn-outline-light {
  border-color: rgba(0, 0, 0, 0.15);
}

.toggle input[type=checkbox] {
  display: none;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle-group label, .toggle-group span {
  cursor: pointer;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 0;
  border-width: 0 1px;
  background-color: #fff;
}

.toggle.btn-outline-primary .toggle-handle {
  background-color: var(--primary);
  border-color: var(--primary);
}

.toggle.btn-outline-secondary .toggle-handle {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.toggle.btn-outline-success .toggle-handle {
  background-color: var(--success);
  border-color: var(--success);
}

.toggle.btn-outline-danger .toggle-handle {
  background-color: var(--danger);
  border-color: var(--danger);
}

.toggle.btn-outline-warning .toggle-handle {
  background-color: var(--warning);
  border-color: var(--warning);
}

.toggle.btn-outline-info .toggle-handle {
  background-color: var(--info);
  border-color: var(--info);
}

.toggle.btn-outline-light .toggle-handle {
  background-color: var(--light);
  border-color: var(--light);
}

.toggle.btn-outline-dark .toggle-handle {
  background-color: var(--dark);
  border-color: var(--dark);
}

.toggle[class*=btn-outline]:hover .toggle-handle {
  background-color: var(--light);
  opacity: 0.5;
}

.toggle.btn {
  min-width: 3.7rem;
  min-height: 2.15rem;
}

.toggle-on.btn {
  padding-right: 1.5rem;
}

.toggle-off.btn {
  padding-left: 1.5rem;
}

.toggle.btn-lg {
  min-width: 5rem;
  min-height: 2.815rem;
}

.toggle-on.btn-lg {
  padding-right: 2rem;
}

.toggle-off.btn-lg {
  padding-left: 2rem;
}

.toggle-handle.btn-lg {
  width: 2.5rem;
}

.toggle.btn-sm {
  min-width: 3.125rem;
  min-height: 1.938rem;
}

.toggle-on.btn-sm {
  padding-right: 1rem;
}

.toggle-off.btn-sm {
  padding-left: 1rem;
}

.toggle.btn-xs {
  min-width: 2.19rem;
  min-height: 1.375rem;
}

.toggle-on.btn-xs {
  padding-right: 0.8rem;
}

.toggle-off.btn-xs {
  padding-left: 0.8rem;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/css/fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.jumbotron {
  background-color: #f8f9fa;
}

.btn-plus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 2vh;
  bottom: 2vh;
  z-index: 9;
}

.btn-table {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
  .animated-checkbox.checkbox-xl-2 input[type=checkbox] + .label-text:before {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .animated-checkbox.checkbox-xs-2 input[type=checkbox] + .label-text:before {
    font-size: 30px;
  }
  .img-size-sm {
    height: 20vh;
  }
}
form {
  margin-block-end: 0;
}

.filter-footer {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.checked {
  position: absolute;
  right: 0;
  top: -3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.image-card {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.verified {
  letter-spacing: -5px;
  font-size: 1.3em;
}

.checked-one {
  right: 0;
}

.checked-two {
  right: 40px;
}

.checked-three {
  right: 80px;
}

.unchecked {
  color: #FF0000 !important;
}

.box-bt-image {
  position: absolute;
  top: 0px;
}

.mn-text-truncate {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card-disabled {
  opacity: 0.2;
}

.bt-select-image {
  right: 2px;
  bottom: 2px;
  color: #FFFFFF !important;
}

gap {
  margin: 2px;
  color: #FFF;
  background-color: #3085D6;
  border-color: #3085D6;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
}

.animated-checkbox.checkbox-lg input[type=checkbox] + .label-text:before {
  font-size: 40px;
}

.mq-text-mode {
  font-family: "tuffy2" !important;
}

.form-group.required label:after {
  content: "*";
  color: red;
  font-weight: bold;
}

.datetimepicker {
  z-index: 99999999;
}

.bootstrap-select > .dropdown-toggle {
  border: 1px solid #CED4DA;
}

.bootstrap-select button {
  border: 1px solid #CED4DA;
}

.bootstrap-select .dropdown-menu {
  width: inherit;
}

.mark {
  background-color: #C0FFC8 !important;
}

.icon-dashboard {
  display: flex;
  min-width: 85px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 0;
  font-size: 2.5rem;
}

.icon-dashboard .material-icons {
  font-size: 36px;
}

.badge-questions {
  padding: 0;
  display: flex;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 0 2px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3);
  user-select: none;
}
.badge-questions.badge-questions-sm {
  width: 20px;
  height: 20px;
}
.badge-questions.badge-success {
  background-color: #81ad8b;
}
.badge-questions.badge-warning {
  background-color: #e5d194;
}
.badge-questions.badge-danger {
  background-color: #d3888f;
}

.permission-body .bootstrap-tagsinput {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.icon-side {
  width: 61px;
  min-height: 61px;
  height: 100%;
  margin: -20px 0px -35px -20px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
@media (max-width: 949px) {
  .icon-side {
    width: 82px;
    min-height: 82px;
  }
}
.icon-side.with-buttons {
  width: 75px;
  min-height: 75px;
}
.icon-side span {
  font-size: 40px;
  color: #7A7A7A;
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
  border-radius: 20rem;
}

.toggle.ios .toggle-handle {
  border-radius: 20rem;
}

.rotate-icon:not(.collapsed) {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.btn-outline-secondary {
  background-color: white;
}

.question-editable div {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.alternative-editable div {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.distractor-editable div {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tip-editable div {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.progress {
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  height: 30px;
  position: relative;
}
.progress .progress-bar {
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.progress .progress-bar div {
  width: 40px;
  height: 24px;
  margin-right: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  user-select: none;
}

.progress-report {
  height: 8px !important;
}

.swal2-popup .swal2-actions {
  width: -webkit-fill-available;
  margin: 20px inherit !important;
}
.swal2-popup .swal2-actions .swal2-styled {
  border-radius: 50px;
  padding: 0 40px;
  width: 152px;
  margin: 5px;
  margin: 0 2% !important;
  padding: 8px 30px !important;
}

.rounded-pill {
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.rounded-pill:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.rounded-pill:focus {
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.rounded-pill.bg-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.25);
  border-color: #198754;
}
.rounded-pill.bg-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 202, 240, 0.25);
  border-color: #0dcaf0;
}
.rounded-pill.bg-warning {
  color: #495057;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
  border-color: #ffc107;
}
.rounded-pill option {
  background-color: #ffffff;
  color: #495057;
}

#math-editor .keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
#math-editor .keyboard a {
  border-color: #D9D9D9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 45px;
}
#math-editor .keyboard a:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
}
#math-editor .keyboard a:active {
  box-shadow: none;
  position: relative;
  top: 1px;
}

.owl-nav {
  display: flex;
  place-content: space-between;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  border: 1px solid #CCC !important;
  font-size: 50px !important;
  background: #FFF !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin-top: -28px;
  z-index: 1 !important;
  opacity: 0.5;
}
.owl-nav .owl-prev:active,
.owl-nav .owl-next:active {
  box-shadow: none;
}
.owl-nav .owl-prev span,
.owl-nav .owl-next span {
  display: flex;
  line-height: 0;
  margin-top: -10px;
}
.owl-nav .owl-prev {
  margin-left: -15px;
}
.owl-nav .owl-next {
  margin-right: -15px;
}
.owl-nav .disabled {
  opacity: 0.1;
}

.bg-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000000;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  text-align: center;
  transition: 0.3s ease;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-overlay .percent {
  font-size: 22px;
  color: #FFF;
  position: fixed;
  opacity: 0.8;
}

.bg-overlay-show {
  visibility: visible;
  opacity: 1;
}

fieldset {
  background-color: #f0f4fa;
  padding: 10px;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
fieldset legend {
  background-color: #f0f4fa;
  width: auto;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
}
fieldset .btn-collapse {
  position: relative;
  left: calc(100% - 33px);
  top: -25px;
}

.tag-active {
  background: #CCCCCC;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-width: 120px;
  width: 100%;
  height: 25px;
  position: absolute;
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  top: -25px;
  right: 10px;
  color: #FFF;
  box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.2);
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0 0 0 5px;
  border-radius: 50px !important;
}
.btn-circle:i {
  position: relative;
  top: -1px;
}

.btn-circle-xsm {
  width: 22px;
  height: 22px;
  line-height: 16px;
  font-size: 0.9rem;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 30px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 50px;
  font-size: 1.1rem;
  padding: 20px 0 0 5px;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.btn-circle-title-xs {
  width: auto;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 540px) {
  .btn-circle-title-sm {
    width: auto;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 992px) {
  .btn-circle-title {
    width: auto;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.tile {
  display: flex;
  flex-direction: column;
  place-content: space-between;
}
.tile .tile-no-border {
  padding: 0;
  box-shadow: none;
}
.tile .tile-header {
  margin-bottom: 20px;
}
.tile .tile-header .tile-title {
  color: #253049;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.tile .tile-header .tile-subtitle {
  font-size: 14px;
  color: #8E9AB0;
  font-weight: 600;
}

.tile-with-buttons .tile-header {
  display: flex;
  place-content: space-between;
  align-items: start;
}
@media (max-width: 960px) {
  .tile-with-buttons .tile-header {
    flex-direction: column;
  }
}
@media (max-width: 960px) {
  .tile-with-buttons .tile-header .tile-subtitle {
    margin-bottom: 10px;
  }
}
@media (max-width: 960px) {
  .tile-with-buttons .tile-header .buttons {
    text-align: center;
  }
}
.tile-with-buttons .btn-group .btn {
  font-size: 16px;
  border: 1px solid #CED4DA;
  color: #54565B;
  line-height: 0px;
}
.tile-with-buttons .btn-group .btn:focus {
  box-shadow: none !important;
}
.tile-with-buttons .btn-group .btn.active {
  font-weight: 600;
  color: #FFF !important;
}
.tile-with-buttons .btn-group .btn.active.purple {
  background: linear-gradient(90deg, rgb(133, 95, 185) 0%, rgb(84, 76, 178) 100%);
}
.tile-with-buttons .btn-group .btn.active.orange {
  background: linear-gradient(90deg, rgb(243, 179, 40) 0%, rgb(254, 123, 84) 100%);
}
.tile-with-buttons .btn-group .btn.active.pink {
  background: linear-gradient(90deg, rgb(224, 78, 133) 0%, rgb(180, 85, 163) 100%);
}
.tile-with-buttons .btn-group .btn.active.blue {
  background: linear-gradient(90deg, rgb(64, 170, 210) 0%, rgb(67, 125, 201) 100%);
}
.tile-with-buttons .btn-group .btn.active.grey {
  background: linear-gradient(90deg, rgb(71, 86, 112) 0%, rgb(44, 57, 78) 100%);
}
.tile-with-buttons .btn-group .btn.active.green {
  background: linear-gradient(90deg, rgb(80, 187, 172) 0%, rgb(33, 154, 137) 100%);
}
.tile-with-buttons .btn-group .btn.active.cyan {
  background: linear-gradient(90deg, rgb(117, 203, 217) 0%, rgb(53, 143, 158) 100%);
}

.skill-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 200px;
  height: 230px;
  display: flex;
  flex-direction: column;
  place-content: space-between;
}
.skill-card .skill-card-header {
  padding: 10px 10px 0 10px;
  display: flex;
  place-content: space-between;
}
.skill-card .skill-card-header .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #212529;
}
.skill-card .skill-card-header span {
  border-radius: 6px;
  font-size: 20px;
  padding: 5px;
  color: #FFF;
}
.skill-card .skill-card-body {
  margin: 0 10px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 6px;
}
.skill-card .skill-card-body .value {
  color: #212529;
}
.skill-card .skill-card-footer {
  padding: 0 10px 10px 10px;
}
.skill-card .skill-card-footer .btn {
  line-height: 17px;
  color: #FFF;
}
.skill-card.skill-card-success {
  background-color: #cce9d2;
}
.skill-card.skill-card-success .skill-card-header span {
  background-color: #7cd68f;
}
.skill-card.skill-card-success .skill-card-body .value {
  color: #20bc40;
}
.skill-card.skill-card-warning {
  background-color: #fff0c4;
}
.skill-card.skill-card-warning .skill-card-header span {
  background-color: #f2d067;
}
.skill-card.skill-card-warning .skill-card-body .value {
  color: #e9b300;
}
.skill-card.skill-card-attention {
  background-color: #f9c295;
}
.skill-card.skill-card-attention .skill-card-header span {
  background-color: #f19951;
}
.skill-card.skill-card-attention .skill-card-body .value {
  color: #ee6c00;
}
.skill-card.skill-card-danger {
  background-color: #f6ced2;
}
.skill-card.skill-card-danger .skill-card-header span {
  background-color: #e97f8b;
}
.skill-card.skill-card-danger .skill-card-body .value {
  color: #e54153;
}
.skill-card.skill-card-info {
  background-color: #cee9f6;
}
.skill-card.skill-card-info .skill-card-header span {
  background-color: #7fd4e9;
}
.skill-card.skill-card-light .skill-card-body .value {
  color: gray;
}