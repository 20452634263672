.bg-overlay {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000000;
	visibility: hidden;
	opacity: 0.0;
	overflow: hidden;
	text-align: center;
	transition: 0.3s ease;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.percent {
		font-size: 22px;
		color: #FFF;
		position: fixed;
		opacity: 0.8;
	}
}

.bg-overlay-show {
	visibility: visible;
	opacity: 1.0;
}