.rounded-pill {
	color: #ffffff;
	border: 1px solid rgba(0,0,0,0.6);
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:focus {
		color: #ffffff;
		border: 1px solid rgba(0,0,0,0.6);
	}

	&.bg-success:focus {
		box-shadow: 0 0 0 .2rem rgba(25,135,84,.25);
		border-color: #198754;
	}

	&.bg-info:focus {
		box-shadow: 0 0 0 .2rem rgba(13,202,240,.25);
		border-color: #0dcaf0;
	}

	&.bg-warning {
		color: #495057;
		box-shadow: 0 0 0 .2rem rgba(255,193,7,.25);
		border-color: #ffc107;
	}

	option {
		background-color: #ffffff;
		color: #495057;
	}
}