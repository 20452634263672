$radius: 12px;

.tag-active {
    background: #CCCCCC;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    max-width: 120px;
    width: 100%;
    height: 25px;
    position: absolute;
    text-align: center;
    font-weight: bold;
    line-height: 25px;
    top: -25px;
    right: 10px;
    color: #FFF;
    box-shadow: inset 0 -1px 3px rgba(0,0,0,0.2);
}